import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import useRouteParam from '../../hooks/useRouteParam'
import ColumnBtnCard from '../atoms/columnBtnCard'
import ColumnH1 from '../atoms/columnH1'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .list-btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    a {
      border-radius: 25vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      background: #19ba9b;
      border: solid 2px #19ba9b;
      width: 200px;
      height: 50px;

      &:hover {
        opacity: 1;
        background: #ffffff;
        color: #3ec7b3;
        text-decoration: underline solid rgba(255, 255, 255, 0);
      }
    }
  }
`

/**
 * 最新記事カードセット
 * TODO 川上申し送り事項：ここでは、新着記事6件が表示される予定です
 * @constructor
 */
const ColumnNewCards = () => {
  const routeParam = useRouteParam('/column/details/C0075/')
  const routeParam2 = useRouteParam('/column/details/C0074/')
  const routeParam3 = useRouteParam('/column/details/C0066/')
  const routeParam4 = useRouteParam('/column/details/C0065/')
  const routeParam5 = useRouteParam('/column/details/C0064/')
  const routeParam6 = useRouteParam('/column/details/C0063/')

  return (
    <Wrapper>
      <ColumnH1 label="最新コラム" />
      <section>
        <ColumnBtnCard
          href={routeParam}
          imgId="c0075.jpg"
          label="週休3日制についてメリットやデメリット、導入するにあたってのポイントとは？"
          date="2024.09.26"
        />
        <ColumnBtnCard
          href={routeParam2}
          imgId="c0074.jpg"
          label="年俸制を採用した場合、賞与の有無や残業代はどうなる？メリットやデメリットについても解説！"
          date="2024.09.04"
        />
        <ColumnBtnCard
          href={routeParam3}
          imgId="c0066.jpg"
          label="ハイブリッドワークとは？メリットやデメリット、成功させるポイントについて解説！"
          date="2024.08.19"
        />
        <ColumnBtnCard
          href={routeParam4}
          imgId="c0065.jpg"
          label="年次有給休暇管理簿とは？対象者、罰則、作成方法、保存期間などについて解説"
          date="2024.07.26"
        />
        <ColumnBtnCard
          href={routeParam5}
          imgId="c0064.jpg"
          label="コロナ禍で変わった労働環境。有給取得率や残業は？"
          date="2024.07.05"
        />
        <ColumnBtnCard
          href={routeParam6}
          imgId="c0063.jpg"
          label="勤怠管理と給与計算の連携について。なぜ連携した方がいいの？留意すべきポイントとは"
          date="2024.06.21"
        />
        {/* <ColumnBtnCard
          href={routeParam6}
          imgId="c0092.jpg"
          label="勤怠管理のワークフローはシステム化できる！メリットや選び方を解説"
          date="2024.03.21"
        /> */}
      </section>
      <div className="list-btn-wrap">
        <Link className="list" to={useRouteParam('/column/list/')}>
          記事一覧
        </Link>
      </div>
    </Wrapper>
  )
}

export default ColumnNewCards
